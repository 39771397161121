@import "../../node_modules/bootstrap/dist/css/bootstrap.css";
//@import "../../node_modules/bootstrap/dist/css/bootstrap-grid.css";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";

@import 'spec/settings/index';
@import 'spec/tools/index';
@import 'spec/index';

.dropdown-menu > a:hover, .dropdown-menu > a:focus {
  color: #FFFFFF !important;
  font-weight: bold !important;
  background-color: rgb(0, 116, 217) !important;
}

.fc-event {
  opacity: 0.75 !important;
}

.fc-event-main-frame, .fc-event-title {
  opacity: 1 !important;
}

.disabled-element {
  pointer-events: none;
  opacity: 0.5;
}

.fc-day-today{
  background-color: rgba(0, 123, 255, 0.15) !important;
  //background-color: rgba(90, 170, 255, 0.15) !important;
}

.fc-non-business{
  background-image: -webkit-repeating-linear-gradient(-45deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%) !important ;
  background-image: -moz-repeating-linear-gradient(-45deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%) !important;
  background-image: -ms-repeating-linear-gradient(-45deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%) !important;
  background-image: -o-repeating-linear-gradient(-45deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%) !important;
  background-image: repeating-linear-gradient(-45deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%) !important;
  -webkit-background-size: 6px 6px !important;
  -moz-background-size: 6px 6px !important;
  -o-background-size: 6px 6px !important;
  background-size: 6px 6px !important;
}